// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-consulting-js": () => import("./../../../src/pages/consulting.js" /* webpackChunkName: "component---src-pages-consulting-js" */),
  "component---src-pages-get-a-quote-js": () => import("./../../../src/pages/get-a-quote.js" /* webpackChunkName: "component---src-pages-get-a-quote-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infused-js": () => import("./../../../src/pages/infused.js" /* webpackChunkName: "component---src-pages-infused-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-why-you-need-an-arborist-js": () => import("./../../../src/pages/why-you-need-an-arborist.js" /* webpackChunkName: "component---src-pages-why-you-need-an-arborist-js" */)
}

